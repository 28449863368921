<template>
<div class="main">
	<div class="sign-in-deets">
		<p>Sign In or Create an Account for access to...</p>
		<p style="margin-left: 1em;">
			<span><i class="fa fa-check"></i> Your Profile</span>
			<span><i class="fa fa-check"></i> Order History</span>
			<span><i class="fa fa-check"></i> Wish Lists</span>
			<span v-if="getClientHasMemberships"><i class="fa fa-check"></i> Memberships</span>
			<span v-if="getClientHasWaivers"><router-link to="/myaccount/waivers"><i class="fa fa-check"></i> Waivers</router-link></span>
		</p>
	</div>
	<div class="columns columns-2 grid-start">
		<div class="column login" v-if="!this.$route.query.contact_id">
			<h2>Sign In</h2>
			<label class="full-width" for="username-field">Email Address / Username</label>
			<input type="text" id="username-field" v-model="user_name">

			<template v-if="!showForgotPassword && !showResetPassword">
				<label class="full-width" for="forgot-password-field"><span v-if="temporaryPasswordSent">Temporary </span>Password</label>
				<input type="password" id="forgot-password-field" v-model="password" @keyup.enter="doLogin()">
			</template>

			<template v-if="showResetPassword">
				<label class="full-width" for="new-password-field">Enter a New Password</label>
				<input type="password" id="new-password-field" v-model="password">
				<label class="full-width">Retype New Password:</label>
				<input for="reenter-password-field" type="password" v-model="retypePassword">
				<div class="button forgot action" id="reenter-password-field" @click="doPasswordReset()">Reset Password and Login</div>
			</template>

			<div class="controls" v-if="!showForgotPassword && !showResetPassword">
				<div class="button action" @click="doLogin()">Sign In</div>
			</div>

			<div class="forgot">
				<div class="link" @click="showForgotPassword = true" v-if="!showForgotPassword && !temporaryPasswordSent">Forgot your password?</div>
				<template v-if="showForgotPassword">
					<div v-if="!temporaryPasswordSent">Enter the email address / username you normally use to log in and we will send a new temporary password with instructions for logging in again.<br><br></div>
					<button class="button action" @click.stop.prevent="doForgotPassword" v-if="!temporaryPasswordSent">Reset My Password</button>
					<button class="button cancel" @click.stop.prevent="showForgotPassword = false" v-if="!temporaryPasswordSent">Cancel</button>
				</template>
			</div>

			<div v-if="message" style="margin: 1em 0; color:red;">{{ message }}</div>
		</div>
		<div class="column create-account">
			<h2>Create an Account</h2>
			<label class="full-width" for="email-address">Email Address <i style="float: right;">this will be your username</i></label>
			<input type="text" id="email-address" v-model="newUser.email_address">
			<label class="full-width" for="password-field">Password</label>
			<input type="password" id="password-field" v-model="newUser.password">
			<label class="full-width" for="confirm-password-field">Confirm Password</label>
			<input type="password" id="confirm-password-field" v-model="newUser.retypePassword">
			<div v-if="$route.query.contact_id" :class="['button', loading ? 'disabled' : 'action']" @click="createUser($route.query.contact_id)">Create Account</div>
			<div v-else :class="['button', loading ? 'disabled' : 'action']" @click="createAccount()">Create Account</div>
			<div v-if="newUserMessage" style="margin: 1em 0;">{{ newUserMessage }}</div>
		</div>
	</div>
</div>
</template>

<script>
export default {
	name: '',
	props: [ 'contact_id', 'email_address' ],
	data() {
		return {
			loading: false,
			user_name: null,
    		password: null,
			emailAddress: null,
    		retypePassword: null,
    		returnTo: null,
    		showForgotPassword: false,
    		temporaryPasswordSent: false,
    		showResetPassword: false,
			window: {
				width: 0
			},
			newUser: {
				user_name: null,
				password: null,
				email_address: null,
				retypePassword: null
			},
    		message: '',
			newUserMessage: ''
		}
	},
	created() {
		this.returnTo = (this.$route.query.return) ? decodeURI(this.$route.query.return) : null
		if (this.$route.query.logout) {
			this.logout()
		}
		if (this.$route.query.reset_password) {
			this.showResetPassword = false
			this.showForgotPassword = false
			this.temporaryPasswordSent = true;
		}
		window.addEventListener('resize', this.handleResize);
        this.handleResize();
   		},
   		destroyed() {
        window.removeEventListener('resize', this.handleResize);
	},
	mounted() {
		if (this.$route.query.contact_id) {
			this.newUser.email_address = this.$route.query.email_address
		}
	},
	methods: {
		doLogin(user) {
		    this.message = ''
			if (user) {
				var data = { user_name: user.user_name, password: user.password }
			} else {
				if (!this.user_name || !this.password) return
				var data = { user_name: this.user_name, password: this.password }
			}
			this.loading = true
    		this.apiCall({ destination: 'login', data: data }).then(obj => {
				this.loading = false
    			if (obj.status == 'success') {
					// now that user is logged in, call get_client
					this.apiCall({ destination: 'get_client' })
					if (obj.force_password_change) {
						this.showResetPassword = true
					} else {
						if (obj.redirect) {
							this.$router.push('/' + obj.redirect)
						} else if (this.returnTo) {
		    				document.location.replace(this.returnTo)
		    			} else if (this.window.width < 768) {
							this.$router.push('/myaccount')
						} else {
		    				this.$router.push('/myaccount/profile')
		    			}
		    		}
    			} else {
	    			this.message = obj.message
    			}
    		})
    	},
    	doForgotPassword() {
	    	if (!this.user_name) return
		    this.message = ''
    		this.apiCall({ destination: 'forgot_password', data: { user_name: this.user_name, login_url: document.location.protocol + '//' + document.location.hostname + '/myaccount/login?reset_password=true' }}).then(obj => {
    			if (obj.status == 'success') {
					this.showForgotPassword = false
					this.temporaryPasswordSent = true;
					this.message = "Check your email for your new temporary password."
    			}
    		})
	    },
	    doPasswordReset() {
		    this.message = ''
    		if (!this.retypePassword || !this.password) return
    		if (this.retypePassword != this.password) {
	    		this.message = 'Passwords do not match'
	    		return
	    	}
		    this.message = ''
    		this.apiCall({ destination: 'reset_password', data: { user_id: this.getUser.user_id, password: this.password }}).then(obj => {
    			if (obj.status == 'success') {
					if (obj.redirect) {
						this.$router.push('/' + obj.redirect)
					} else if (this.returnTo) {
	    				document.location.replace(this.returnTo)
	    			} else {
	    				this.$router.push('/myaccount')
	    			}
    			} else {
	    			this.message = obj.message
    			}
    		})
		},
        createAccount() {
			if (this.loading) return
            if (!this.newUser.email_address || ! this.newUser.password) {
                this.newUserMessage = "All fields are required."
                return false
            }
            if (this.newUser.password !== this.newUser.retypePassword) {
                this.newUserMessage = "Passwords must match."
                return false
            }
			this.loading = true
            this.apiCall({ destination: 'save_item_contact', data: { email_address: this.newUser.email_address } }).then((obj) => {
				this.loading = false
                if (obj.status == 'success') {
					this.createUser(obj.contact_id)
                } else {
                    this.newUserMessage = obj.message
                }
            })
        },
		createUser(contactId) {
			if (this.loading) return
			this.loading = true
			var data = {
				contact_id: contactId,
				user_name: this.newUser.email_address,
				new_password: this.newUser.password,
				user_type_code: 'CUSTOMER'
			}
			this.apiCall({ destination: 'save_item_user', data: data }).then((obj) => {
                if (obj.status == 'success') {
                    this.newUserMessage = 'Your account has been created. Logging you in now.'
					this.doLogin({ user_name: this.newUser.email_address, password: this.newUser.password })
                } else {
					this.loading = false
					this.newUserMessage = obj.message
                }
			})
		},
		logout() {
			this.apiCall({ destination: 'logout' }).then(obj => {
				// fd_token was set in store.js when user logged in
				sessionStorage.removeItem('fd_token')
				//this.$router.push('/')
			})
		},
		handleResize() {
            this.window.width = window.innerWidth;
        }
	}
}
</script>

<style lang="scss" scoped>
.columns {
	grid-gap: 1em 4rem;
	.column {
		display: grid;
		grid-auto-flow: row dense;
		input {
			width: auto;
		}
		.button, .controls.button {
			display: grid;
			align-items: center;
			margin: 1em 0;
		}
		.forgot {
			div.link {
				cursor: pointer;
				width: max-content;
				font-size: 0.8rem;
			}
		}
	}
}
.sign-in-deets {
	span {
		margin: 0 1em 0 0;
	}

    @media(max-width: 768px) {
        span { display: block; }
    }

}
.main:not(.sections) {
 background-color: transparent;
}
</style>
